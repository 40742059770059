@tailwind base;

@layer base {
  html {
    @apply text-gray-900;
  }
}

@tailwind components;
@tailwind utilities;
